.btn-sort {
    min-width: 120px;
    border: 2px solid $gray-500;
    border-radius: 7px;
    padding: 8px;
    color: $black;

    &:hover, &.active {
        border: 2px solid $blue;
        background-color: $blue;
        color: $white;
    }
}
