.quick-search-link {
    background-color: $blue;
    color: $white;
    border-radius: 10px;
    padding: 30px 20px;
    min-height: 140px;
    box-shadow: -10px 15px 0px 2px $white;
    border: 2px solid $white;
    transition: ease 1s;

    .title {
        position: relative;
        display: block;
        width: 75%;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .arrow {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px 40px;
        transition: ease 1s;

        path {
            fill: $white;
        }
    }

    &:hover {
        box-shadow: -10px 15px 0px 2px $pink;
        border: 2px solid $pink;

        .arrow {
            transform: rotate(360deg);

            path {
                fill: $pink;
            }
         }
    }

}