.form-link {
    background-color: $green;
    color: #eee;
    border-radius: 10px;
    padding: 40px 20px;
    min-height: 300px;
    box-shadow: -10px 15px 0px 2px $gray-700;
    transition: ease 1s;

    .title {
        position: relative;
        display: block;
        width: 65%;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .plus {
        position: absolute;
        top: 0;
        right: 0;
        padding: 20px 40px;
        color: $gray-700;
        transition: ease 1s;

        path {
            fill: $gray-700;
        }
    }

    .description {
        position: relative;
        display: block;
        width: 75%;
        font-size: 20px;
        font-weight: 300;
    }

    &:hover {
        box-shadow: -10px 15px 0px 2px $pink;

        .plus {
            transform: rotate(360deg);
            path {
                fill: $pink;
            }
         }
    }

}