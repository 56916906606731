.header-admin {
	background: $black;
	padding-top: 0;
	padding-bottom: 0;

	.navbar-brand {
		font-size: 30px;
		font-weight: 600;
		margin-right: 30px;
	}

	.nav-link {
		color: $white !important;
		padding: 10px;

		&:hover {
			background: $blue;
		}

		&.active {
			background: $blue;
		}

		@media all and (min-width: 768px) {
			font-size: 20px;
			font-weight: 600;
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}

	.nav-item {
		margin: 0;
		
		@media all and (min-width: 768px) {
			margin-left: 10px;
			margin-right: 10px;			
		}
	}
}
