.activity-selector {
    background-color: $blue;
    border-bottom: 1px solid $white;
    margin: 0;
    padding: 0;

    label.info {
        width: 150px;
    }

    .btn-activity {
        min-width: 160px;
        border: 2px solid $white;
        border-radius: 7px;
        padding: 8px;
        color: $white;

        &:hover, &.active {
            background-color: $white;
            color: $blue;
        }
    }
}

.btn-activity-selector {
    min-width: 150px;
    border: 2px solid $blue;
    border-radius: 7px;
    padding: 7px;
    color: $blue;

    &:hover, &.active {
        background-color: $blue;
        color: $white;
    }
}