.dashboard-filter {
	background: $blue;
}

.dashboard-form-group {
	display: flex;
	justify-content: space-between;

	input {
		max-width: 600px;
	}

	label {
		color: $white;
		display: flex;
		align-items: center;
	}
}

.tab-filter-form-group {
	label {
		margin-bottom: 0;
	}
}