body {
	line-height: 28px;
}

img, iframe {
	max-width: 100%;
}

input[type=checkbox] {
	-webkit-appearance: none;
	width: 37px;
	height: 37px;
	min-width: 37px;
	background: #ffffff;
	vertical-align: middle;
	position: relative;
	transition: .2s all;

	&:hover {
		background: $blue;
		border: 5px solid $white !important;
		outline: 1px solid $blue;
	}

	&:checked {
		background: $black;
		border: 5px solid $white !important;
		outline: 1px solid $black;
	}
}

select {
	-webkit-appearance: none;
	border-radius: 0;
}

.mr-lg-14 {
	@media all and (min-width: 1024px) {
		margin-right: 14px !important;
	}
}