.toast-btn {
	padding: 20px;
}

.toast-btn-body {
    font-size: 22px;
    min-height: 80px;
    padding: 20px;
}

.Toastify__close-button {
    align-self: center !important;
}

.Toastify__toast-container--top-center {
    top: 1em;
    left: 45%;
    margin-left: -160px; 
}

.Toastify__toast-container {
    width: 460px;
}