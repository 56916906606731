.react-datepicker {
	border-radius: 0 !important;
	border: 1px solid #28A2FB !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
	background-color: #28A2FB !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
	background-color: #28A2FB !important;
	border-radius: 0;
}

.react-datepicker__navigation--next {
	border-left-color: #28A2FB !important;
}

.react-datepicker__navigation--previous {
	border-right-color: #28A2FB !important;
}