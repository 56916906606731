.tab-filters {
	padding: 20px;
	color: $white;
}

.tab-filter-item {
	border: 0;
}

.tab-filter-item-border {
	@media all and (min-width: 768px) {
		border-left: 1px solid $blue;
	}
}

.tab-filter-label {
	display: flex;
	align-items: center;
}

.tab-filter-toggle {
	color: $white;
	font-weight: 600;
	font-size: 18px;
	display: inline-flex;
	align-items: center;

	i {
		font-size: 25px;
		margin-right: 10px;
	}
}

.tab-filter-checklist {
	display: flex;
	flex-direction: column;

	@media all and (min-width: 768px) {
		flex-direction: row;
	}
}

.tab-filter-form-group {
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	label {
		color: $white;
		margin-right: 3px;
	}

	@media all and (min-width: 768px) {
		flex-direction: row;
	}
}

.tab-filter-select,
.tab-filter-input {
	color: $blue;

	@media all and (min-width: 768px) {
		width: 400px;
	}

	&::placeholder {
		color: $blue;
	}
}

.tab-filter-select {
	background-image:
	    -webkit-linear-gradient(bottom left, transparent 50%, $blue 50%),
	    -webkit-linear-gradient(top left, $blue 50%, transparent 50%);
	background-image:
	    -o-linear-gradient(bottom left, transparent 50%, $blue 50%),
	    -o-linear-gradient(top left, $blue 50%, transparent 50%);
	background-image:
	    linear-gradient(to top right, transparent 50%, $blue 50%),
	    linear-gradient(to bottom right, $blue 50%, transparent 50%);
  	background-position:
	    calc(100% - 20px) calc(1em + 0px),
	    calc(100% - 10px) calc(1em + 0px);
  	background-size:
	    10px 10px,
	    10px 10px;
  	background-repeat: no-repeat;
  	width: 100%;
}

.tab-filter-select-wrap {
	position: relative;

	.fa {
		color: $blue;
		font-size: 35px;
		position: absolute;
		top: 30%;
		right: 10px;
		transform: translateY(-50%);
	}
}