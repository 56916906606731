
    .area-link {
        background-color: $gray-700;
        color: #eee;
        border-radius: 10px;
        padding: 40px 20px;
        min-height: 320px;
        box-shadow: -10px 15px 0px 2px $blue;
        position: relative;
        transition: ease 1s;

        .title {
            position: relative;
            display: block;
            width: 65%;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        .description {
            position: relative;
            display: block;
            width: 75%;
            font-size: 20px;
            font-weight: 300;
        }

        .arrow {
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px 40px;
            color: $blue;
            transition: ease 1s;
        }

        .icon {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 20px 40px;
            color: $gray-500;
            transition: ease 1s;

            path {
                transition: ease 1s;
            }
        }

        &:hover {
            box-shadow: -10px 15px 0px 2px $pink;

            .arrow {
                transform: rotate(360deg);
                path {
                    fill: $pink;
                }
             }

            .icon {
                path {
                    fill: $gray-400;
                }
            }
        }
    }