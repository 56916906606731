.lookup-logo {
    display: block;
    width: 320px;
    height: 24px;
   
    svg {
        width: 100%;
        height: 75px;
    }

    &:hover {
        path:last-of-type {
            fill: $pink;
        }

        path:nth-last-of-type(6) {
            fill: $pink;
        }

        path:nth-last-of-type(9) {
            fill: $pink;
        }
        path:nth-last-of-type(1) {
            fill: $pink;
        }

        path:nth-last-of-type(7) {
            fill: $pink;
        }

        path:nth-last-of-type(8) {
            fill: $pink;
        }

        path:nth-last-of-type(9) {
            fill: $pink;
        }
    }
}

.btn-primary-nav {
    border-radius: 7px;
    border: 2px solid $primary;
    color: $white;
    background-color: $primary;

    &:hover {
        background-color: $white;
        color: $primary;
    }

    &.active {
        background-color: $white;
        color: $primary;
    }
}

.btn-success-nav {
    border-radius: 7px;
    border: 2px solid $success;
    color: $white;
    background-color: $success;

    &:hover {
        background-color: $white;
        color: $success;
    }

    &.active {
        background-color: $white;
        color: $success;
    }
}

.btn-contact-nav {
    border-radius: 7px;
    border: 2px solid $gray-600;
    color: $white;
    background-color: $gray-600;

    &:hover {
        background-color: $white;
        color: $black;
    }

    &.active {
        background-color: $white;
        color: $black;
    }
}