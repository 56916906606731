.dashboard-results {
	padding-top: 40px;
	padding-bottom: 40px;
}

.dashboard-results-title {
	text-transform: uppercase;
	font-size: 20px;
	margin-bottom: 20px;
}

.dashboard-results-table {
	thead th,
	.table-head-button {
		color: $blue;
		text-transform: uppercase;
	}

	.table-head-button {
		font-weight: bold;
		border: 0;
		background: white;
		padding: 0;
	}
}