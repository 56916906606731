.social {
	padding: 8px 10px;
	margin-bottom: 0px;

	li {
		display: inline-block;
		float: left;

		&:last-of-type {
			a {
				margin-right: 0;
			}
		}
	}

	a {
		display: block;
		width: 20px;
		height: 20px;
		float: left;
		margin: 0;
        color: $black;
        font-size: 17px;

		@media all and (min-width: 1024px) {
			font-size: 27px;
			width: 35px;
			height: 30px;
		}

        &:hover, &:focus {
            color: $red;
        }

        + a {
            margin: 0 0 0 1rem;
        }

        .text {
            position: absolute;
            top: -1000%;
        }
	}
}
