.pagination {
	padding: 20px 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.pagination .page-item .page-link {
	border: 0;
	font-size: 25px;
	background: none;
}

.pagination .page-item.active .page-link {
	color: #F624CB;
}