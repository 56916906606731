.section-title {
	font-size: 35px;
}

.section-standfirst {
	font-size: 24px;
}

.text-underline {
	text-decoration: underline;
}

.list-unstyled{
	list-style-type: none;
}

.specific-keystage {
	margin-bottom: 0;

	@media all and (min-width: 1200px) {
		white-space: nowrap;
	}

	@media all and (min-width: 768px) and (max-width: 1023px) {
		white-space: nowrap;
	}
}