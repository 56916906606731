.tab-filter-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;

	@media all and (min-width: 768px) {
		flex-direction: row;
	}
}

.tab-filter-btn {
	padding: 20px;
	width: 100%;
	font-weight: 600;
	margin-left: 2px;
	margin-right: 2px;

	&.btn-tab-blue.active {
		background: $blue !important;
		color: $white !important;
	}

	&.btn-tab-green.active {
		background: $green !important;
		color: $white !important;
	}

	@media all and (min-width: 768px) {
		font-size: 16px;
	}

	@media all and (min-width: 1024px) {
		font-size: 20px;
	}
}

.btn-tab-blue {
	color: $blue;

	&:hover {
		background: $blue;
		color: $white;
	}
}

.btn-tab-green {
	color: $green;

	&:hover {
		background: $green;
		color: $white;
	}
}