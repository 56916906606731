#footer {
	background-color: $black;
	color: $white;
	padding: 5rem 0;

	a {
		color: $white;
		text-decoration: none;

		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	p, li, h2 {
        margin-top: 0;
	}

	h2 {
        margin-top: 0;
        color: $red;
        font-size: 18px;
	}

    .footer-list {
        list-style: none;
        padding: 0;
    }

	#footer-information {
		span.locality {
			display: block;
		}

		#address, 
        .links-wrapper {
			width: 22.22222222%;
			float: left;
		}

		#logos {
			float: right;
            width: calc(100% / 3);
		}
	}

	#row2 {
        margin-top: 5rem;
        overflow: hidden;
        display: block;

		.col > * {
			display: inline-block;
            float: left;
		}

        .col > p:first-of-type {
            width: 22.22222222%;
        }

        .col > div {
            float: right;
            width: calc(100% / 3);
        }

        p {
            margin-bottom: 0;
        }
	}

    a {
        &[href^="tel"], &[href^="mailto"] {
            color: $red;
        } 
    }

	.credit  a {
        strong {
            color: $red;
        }

        &:hover, &:focus {
            text-decoration: none;

            strong {
                text-decoration: underline;
            }
        }
    }
}

@media all and (max-width: 1000px) {
    #footer {
        #footer-information {
            #address,
            #logos,
            .links-wrapper {
                width: 25%;
                vertical-align: top;
            }
        }
    }
}

.footer-bottom-wrap {
    flex-direction: column;

    @media all and (min-width: 768px) {
        flex-direction: row;
    }

    .footer-bottom-wrap-item {
        text-align: center;

        @media all and (min-width: 768px) {
            text-align: left;
        }
    }
}

@media all and (max-width: 768px) {
	#footer {
		#footer-information {
			#address,
            .links-wrapper,
            #logos {
				text-align: center;
				width: 600px;
				float: none;
                max-width: 100%;
                margin: auto;

                + * {
                    margin-top: 2rem;
                }
			}
		}

        #row2 {
            margin: 2rem auto 0 auto;
        }

        #row2 {
            > *, p:first-of-type {
				text-align: center;
				width: 600px;
				float: none;
                max-width: 100%;
                margin: auto;
                display: block;
            }

            > div {
                width: 600px;
                float: none;
                margin: 2rem auto 0 auto;
            }
        }
	}
}