.pagination-title,
.pagination-arrow,
.pagination-numbers {
	font-size: 28px;
	color: $blue;
	font-weight: 600;
}

.pagination-num {
	padding-left: 10px;
	padding-right: 10px;
}