header {
	display: block;
	position: relative;
	top: 0;
	left: 0;
	padding: 0;
	height: 64px;
	width: 100%;
	z-index: 9999;
	border-bottom: 4px solid $black;
	background-color: $white;
  
	@media screen and (min-width: 768px) {
	  height: 104px;
	}
  
	.header-container {
	  display: block;
	  overflow: hidden;
	  position: relative;
  
	  @media screen and (max-width: 768px) {
		margin: 0 !important;
		padding: 0 !important;
	  }
  
	  .logo {
		display: block;
		float: left;
		margin: 0;
		margin-left: 12px;
  
		svg {
		  width: 100px;
		}
  
		@media screen and (min-width: 992px) {
		  margin: 12px 0;
		  svg {
			width: 180px;
		  }
		}
  
		&:hover {
		  svg {
			fill: $pink;
		  }
		}
	  }
  
	  .mobile-menu-container {
		display: block;
		position: relative;
		margin: 0;
		padding: 0;


  
		.mobile-menu-toggler {
		  float: right;
		  background-color: transparent;
		  margin: 12px;
		  transition: 0.25s ease-in-out all;
		  border: none;
  
		  svg {
			width: 32px;
			height: 32px;
		  }
  
		  &[aria-expanded="true"] {
			transform: rotate(45deg);
			svg {
			  fill: $pink;
			}
		  }
  
		  &:hover {
			svg {
			  fill: $pink;
			}
		  }
		}
  
	
  
		@media screen and (min-width: 768px) {
		  display: none;
		}
	  }
  
	  .top-nav-container {
		display: none;
		position: relative;
		float: right;
		width: 70%;
  
		@media screen and (min-width: 768px) {
		  display: block;
		}
  
		.social-nav {
		  margin: 0;
		  padding: 0;
		  float: right;
		  list-style: none;

		  @media screen and (min-width: 768px) {
			margin-right: 4px;
		  }

		  @media screen and (min-width: 992px) {
			margin-right: 12px;
		  }
		  
  
		  li {
			float: left;
			margin: 8px 12px 4px 12px;
			padding: 0;
  
			@media screen and (min-width: 992px) {
			  margin: 10px 12px 4px 12px;
			}
  
			a {
			  svg {
				width: 18px;
				height: 18px;
				color: $black;
  
				@media screen and (min-width: 992px) {
				  width: 22px;
				  height: 22px;
				}
  
				&:hover {
				  fill: $blue;
				}
			  }
  
			  .sr-only {
				display: none;
			  }
			}
		  }
		}


		.top-nav {
			margin: 0;
			padding: 0;
			float: right;
			list-style: none;
		  
			li {
			  float: left;
			  margin: 8px 12px;
			  padding: 0;

			  @media screen and (min-width: 992px) {
				margin: 8px 16px;
			  }
		  
			  button {
				border-radius: 0;
				border: none;
				background-color: transparent;
			  }
		  
			  a, button {
				color: $black;
				text-decoration: none;
				text-transform: uppercase;
				font-size: 14px;

				@media screen and (min-width: 992px) {
					font-size: 16px;
				  }
		  
				svg {
				  width: 32px;
				  height: 32px;
				  color: $black;
				}
		  
				.sr-only {
				  display: none;
				}
		  
				&:hover, &.active {
				  color: $pink;
				}
			  }
			}
		  }


		  .mega-nav {

			float: right;
			display: block;
			list-style: none;

			li {
				float: left;
				a {
					font-size: 16px;
					text-decoration: none;
					display: inline-block;
					position: relative;
					color: $black;
					padding: 8px 16px 8px 24px;
					white-space: nowrap;
			  
					@media screen and (min-width: 1200px) {
					  font-size: 23px;
					  padding: 8px 24px 14px 32px;
					}
			  
					&:before {
					  content: "";
					  position: absolute;
					  top: 12px;
					  left: 0;
					  border-top: 18px solid $white;
					  border-right: 18px solid $black;
					  transition: 0.33 ease-in-out all;
			  
					  @media screen and (min-width: 1200px) {
						border-top: 24px solid $white;
						border-right: 24px solid $black;
					  }
				}
			}
			
	  }
	}
	  }
	}


	.mobile-menu-toggler {
		display: block;
		position: absolute;
		top: 12px;
		right: 12px;
		border: none;
		background: transparent;
		transition: 0.33s ease-in-out all;

		@media screen and (min-width: 768px) {
			display: none;
		}

		&.active {
			transform: rotate(45deg);
		}
	}


	.mobile-menu {
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0;
		background-color: $black;
		width: 100%;
		display: none;
		

		&.open {
			display: block;
		}

		.top-nav, .mega-nav {
		  display: block;
		  position: relative;
		  width: 100%;
		  margin: 0;
		  padding: 0;
		  list-style: none;

		  li {
			display: block;
			margin: 0;
			padding: 0;
			width: 100%;
			

			a {
			  border-bottom: 1px solid $grey;
			  color: $white;
			  background-color: $black;
			  display: block;
			  padding: 16px 24px;
			  text-align: center;
			  transition: 0.25s ease-in-out all;

			  &:hover {
				background-color: $pink;
			  }

			  &.mega-link {
				border-top: 1px solid $grey;

				&:hover {
				  background-color: $blue;
				}
			  }
			}
		  }
		}


		.social-nav {
		  display: block;
		  overflow: hidden;
		  margin: 0;
		  padding: 0;
		  list-style: none;
		  border-bottom: 1px solid $grey;

  
		  li {
			float: left;
			margin: 0;
			padding: 24px;
			width: 25%;
  
			a {
			  display: block;
			  margin: 0;
			  padding: 8px;

			  
			  svg {
				width: 24px;
				height: 24px;
				fill: $white;
  
				&:hover {
				  fill: $blue;
				}
			  }
  
			  .sr-only {
				display: none;
			  }
			}
		  }
		}

	  }
}
  
