.dashboard-form-group-dropdown {
	position: absolute;
	top: 0;
	background: white;
	z-index: 9;
	padding: 0;
}

.dashboard-form-group-dropdown-item {
	padding: 10px;
	margin: 0;
}

.dashboard-form-group-dropdown-item:hover {
	background:  #28A2FB;
	color: #fff;
	cursor: pointer;
	border: 1px solid grey;
}